const WalletI18n = {
	zh: {
		walletFocusTitle: 'AA Wallet',
		walletFocusSubTitle: '帐户抽象（AA）是 Web3 大规模采用的关键。 ChainPal AA钱包采用尖端协议ERC-4337，提供流畅的用户体验高安全标准。 ChainPal AA钱包是一款智能合约钱包、一款App钱包、一款100%自我托管的钱包。',
		walletListTitle1: '无需助记词',
		walletListDesc1: 'ChainPal 由 ERC-4337 支持，建立在账户抽象之上，用户不再需要管理他们的私钥。',
		walletListTitle2: '社交恢复+',
		walletListDesc2: '用户可以通过任何其他钱包（包括冷钱包）轻松恢复其帐户。通过电子邮件身份验证提供增强的安全措施。',
		walletListTitle3: '免Gas',
		walletListDesc3: '背靠Paymaster（erc-4337的一部分），高净值用户无需担心gas费用，由 ChainPal 全额赞助。',
		walletListTitle4: '社交网络',
		walletListDesc4: '社交网络功能包括 IM、视频会议等功能',
		walletListTitle5: '结构化产品信息服务',
		walletListDesc5: 'ChainPal提供链上结构化产品信息服务和便捷访问的CryptoGain入口。'
	},
	tr: {
		walletFocusTitle: 'AA Wallet',
		walletFocusSubTitle: '帳戶抽象（AA）是 Web3 大規模採用的關鍵。 ChainPal AA錢包採用尖端協議ERC-4337，提供流暢的用戶體驗高安全標準。 ChainPal AA錢包是一款智能合約錢包、一款App錢包、一款100%自我託管的錢包。',
		walletListTitle1: '無需助記詞',
		walletListDesc1: 'ChainPal 由 ERC-4337 支持，建立在賬戶抽象之上，用戶不再需要管理他們的私鑰。',
		walletListTitle2: '社交恢復+',
		walletListDesc2: '用戶可以通過任何其他錢包（包括冷錢包）輕鬆恢復其帳戶。通過電子郵件身份驗證提供增強的安全措施。',
		walletListTitle3: '免Gas',
		walletListDesc3: '背靠Paymaster（erc-4337的一部分），高淨值用戶無需擔心gas費用，由 ChainPal 全額贊助。',
		walletListTitle4: '社交網絡',
		walletListDesc4: '社交網絡功能包括 IM、視頻會議等功能',
		walletListTitle5: '結構化產品信息服務',
		walletListDesc5: 'ChainPal提供鏈上結構化產品信息服務和便捷訪問的CryptoGain入口。'
	},
	en: {
		walletFocusTitle: 'AA Wallet',
		walletFocusSubTitle: 'Account Abstraction (AA) is the key of Web3 mass adoption. Using cutting edge protocol ERC-4337, ChainPal AA wallet provides smooth user experience together with high security standards. ChainPal AA wallet is a smart contract wallet, an App wallet, and a 100% self - custodial wallet.',
		walletListTitle1: 'No need for mnemonic phrase',
		walletListDesc1: 'Backed by ERC-4337, ChainPal is built on account abstraction and users no longer need to manage their private keys.',
		walletListTitle2: 'Social recovery and more',
		walletListDesc2: 'Users can easily recover their account via any other wallets (including cold wallet) . Provide enhanced security measures with E-Mail authentication.',
		walletListTitle3: 'Gas free',
		walletListDesc3: 'Backed by Paymaster (a unit of erc-4337) , high net worth users don’t need to worry about gas fees, fully sponsored by ChainPal.',
		walletListTitle4: 'Social Networking',
		walletListDesc4: 'Social networking features including IM, video conferences and more.',
		walletListTitle5: 'Structured product information service',
		walletListDesc5: 'ChainPal provides on-chain structured products information service and easy-to-access CryptoGain entrance.'

	},
}
export default WalletI18n