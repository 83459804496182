const PrivacyI18n = {
    zh: {
        PrivacyTitle:'隐私账户',
        PrivacyContent:'ChainPal智能账户钱包基于zk-SNARKs构建隐私账户，利用零知识加密技术来实现任意合约调用与内容加密，且保持首选链安全性。无需依赖单独的Layer2验证器集或托管桥。完全去中心化。'
    },
    tr: {
        PrivacyTitle:'隱私賬戶',
        PrivacyContent:'ChainPal智能賬戶錢包基於zk-SNARKs構建隱私賬戶，利用零知識加密技術來實現任意合約調用與內容加密，且保持首選鏈安全性。無需依賴單獨的Layer2驗證器集或託管橋。完全去中心化。'
    },
    en: {
        PrivacyTitle:'Privacy Account',
        PrivacyContent:'ChainPal smart account wallet builds private accounts based on zk-SNARKs, using zero-knowledge encryption technology to implement arbitrary contract calls and content encryption, while maintaining the security of the preferred chain. No need to rely on a separate set of Layer2 validators or custodial bridges. Completely decentralized.'
    },
}
export default PrivacyI18n