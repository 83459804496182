const UA = window.navigator.userAgent
const LocationSearch = window.location.search
export const isApp = () => {
    return UA.indexOf('ChainMeet') > -1 || UA.indexOf('ChainPal') > -1
}


/**
 * @desc 获取路径参数
 */
export const getQueryValue = (queryName) => {
    let query = decodeURI(LocationSearch.substring(1));
    let vars = query.split("&");
    for (let i = 0;i < vars.length;i++) {
        let pair = vars[i].split("=")
        if (pair[0] === queryName) {
            return pair[1];
        }
    }
    return null;
}