import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import VetaI18n from './veta'
import WalletI18n from './wallet'
import PrivacyI18n from "./privacy";
i18n.use(initReactI18next).init({
	resources: {
		zh: {
			translation: {
				metaTitle: '专注隐私的智能账户基础设施',
				metaKeyWords: '链上会，隐私安全，会议，通信，加密，加密会议，轻应用',
				metaDescription: '链上结构化产品信息服务商',
				appName: '链上会',
				download: '下载',
				langZh: '简体中文',
				langEn: 'English',
				langTr: '繁體中文',
				focusTitle: '专注隐私的智能账户基础设施',
				focusSubTitle: '让数字资产配置更简单、更安全',
				subscribeTitle: 'Subscribe to our newsletter',
				docsTitle:'文档',
				subscribeTip: 'Stay ahead with the least updates from ChainPal',
				subscribeNamePlaceholder: 'Your Name',
				subscribeAddressPlaceholder: 'Email Address',
				subscribeBtn: 'Subscribe',
				downloadDesc1: '扫码访问 <em>UnionDefi</em>',
				footer: '版权所有© 2023.ChainPal。保留所有权利。',
				footerLeft: '版权所有© ',
				footerRight: '.ChainPal。保留所有权利。',
				...VetaI18n.zh,
				...WalletI18n.zh,
				...PrivacyI18n.zh
			},
		},
		tr: {
			translation: {
				metaTitle: '專注隱私的智能賬戶基礎設施',
				metaKeyWords: '鏈上會，隱私安全，會議，通信，加密，加密會議，輕應用',
				metaDescription: '鏈上結構化產品信息服務商',
				appName: '鏈上會',
				download: '下載',
				langZh: '簡體中文',
				langEn: 'English',
				langTr: '繁體中文',
				focusTitle: '專注隱私的智能賬戶基礎設施',
				focusSubTitle: '讓數字資產配置更簡單、更安全',
				subscribeTitle: 'Subscribe to our newsletter',
				docsTitle:'文檔',
				subscribeTip: 'Stay ahead with the least updates from ChainPal',
				subscribeNamePlaceholder: 'Your Name',
				subscribeAddressPlaceholder: 'Email Address',
				subscribeBtn: 'Subscribe',
				downloadDesc1: '掃碼訪問 <em>UnionDefi</em>',
				footer: '版權所有© 2023.ChainPal。保留所有權利。',
				footerLeft: '版權所有© ',
				footerRight: '.ChainPal。保留所有權利。',

				...VetaI18n.tr,
				...WalletI18n.tr,
				...PrivacyI18n.tr
			},
		},
		en: {
			translation: {
				metaTitle: 'ChainPal - A privacy-first smart account infrastructure',
				metaKeyWords:
					'ChainMeet, ChainPal, Privacy Security, Conference, Communication，Encrypted Meeting，Light App, Web3',
				metaDescription: 'Make Digital Asset Allocation Simpler and Safer',
				appName: 'ChainPal',
				download: 'Download',
				langZh: '简体中文',
				langEn: 'English',
				langTr: '繁體中文',
				focusTitle: 'A privacy-first smart account infrastructure',
				focusSubTitle: 'Make Digital Asset Allocation Simpler and Safer',
				docsTitle:'docs',
				subscribeTitle: 'Subscribe to our newsletter',
				subscribeTip: 'Stay ahead with the least updates from ChainPal',
				subscribeNamePlaceholder: 'Your Name',
				subscribeAddressPlaceholder: 'Email Address',
				subscribeBtn: 'Subscribe',
				downloadDesc1: 'Scan to browse <em>UnionDefi</em>',
				footer: 'Copyright © 2024 ChainPal. All rights reserved.',
				footerLeft: 'Copyright © ',
				footerRight: ' ChainPal. All rights reserved.',
				...VetaI18n.en,
				...WalletI18n.en,
				...PrivacyI18n.en
			},
		},
	},
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
