import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import appStoreQr from '../../images/appStore-qr.png'
import vetaQr from '../../images/veta-qr.png'
import googleQr from '../../images/google-qr.png'
import appStoreEn from '../../images/appStore-en.png'
import appStoreZh from '../../images/appStore-zh.png'
import appStoreTr from '../../images/appStore-tr.png'
import googleEn from '../../images/google-en.png'
import googleZh from '../../images/google-zh.png'
import googleTr from '../../images/google-tr.png'
import '../../i18n'
import './index.css'

function Download({currentLanguage}) {
	const { t } = useTranslation()
	const list = useMemo(() => {
		return [
			{ img: vetaQr, desc: t('downloadDesc1') },
			{ img: appStoreQr, icon: currentLanguage === 'en' ? appStoreEn : currentLanguage === 'zh' ? appStoreZh : appStoreTr },
			{ img: googleQr, icon: currentLanguage === 'en' ? googleEn : currentLanguage === 'zh' ? googleZh : googleTr },
		]
	}, [currentLanguage, t])
	return (
		<div className="download-container">
			<h5 className="title">{t('download')}</h5>
			<ul className="qr-list">
				{list.map((item, index) => {
					return (
						<li key={index}>
							<figure>
								{item.desc ? (
									<figcaption dangerouslySetInnerHTML={{ __html: item.desc }}></figcaption>
								) : (
									<img src={item.icon} className="icon" alt="icon" />
								)}
								<img src={item.img} className="qr" alt={item.desc} />
							</figure>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default Download
