const VetaI18n = {
	zh: {
		vetaFocusTitle: '结构化产品保证金交易平台',
		vetaFocusSubTitle: '资产增强，获得额外的波动收益',
		vetaListTitle1: '结构性产品保证金系统',
		vetaListDesc1: 'UnionDefi是一个一体化的结构性产品保证金交易平台，专业人士可以发布结构性产品，用户可以浏览和购买。',
		vetaListTitle2: '发布产品',
		vetaListDesc2: '对于金融机构或专业量化团队来说，他们可以发布定制的结构化产品，既支持DeFi式（智能合约），也支持托管式（CEFFU）。',
		vetaListTitle3: '询报价系统',
		vetaListDesc3: '对于高价值客户，他们可以发布报价请求，指定产品功能和等待专业团队报价。',
		vetaTabsNav1: '本金保护型',
		vetaTabsNav2: '收益增强型',
		vetaTabsContentTitle1: '鲨鱼鳍',
		vetaTabsContentSubTitle1: '保本且有保证的收益',
		vetaTabsContentText11: '在特定市场波动中获得收益。',
		vetaTabsContentText12: '享受本金保护和收益的安全性。',
		vetaTabsContentTitle2: '保底雪球',
		vetaTabsContentSubTitle2: '高收益与可控风险的完美结合',
		vetaTabsContentText21: '下行风险有支撑。',
		vetaTabsContentText22: '充分利用高保护性收益的优势。',

		vetaTabsContentTitle3: '雪球',
		vetaTabsContentSubTitle3: '下跌自带缓冲，上涨不失良机',
		vetaTabsContentText31: '具有高概率的下行保护和高收益。',
		vetaTabsContentText32: '最大损失限制为按初始价格买入基础资产。',

		vetaTabsContentTitle4: 'FCN',
		vetaTabsContentSubTitle4: '保证收益或折扣价格接货',
		vetaTabsContentText41: '具有下行保护和保证收益。',
		vetaTabsContentText42: '适用于以折扣价格建立仓位。',
		vetaEnterBtn: `进入 UnionDefi`,
		vetaBtn:'进入 UnionDefi'

	},
	tr: {
		vetaFocusTitle: '結構化產品保證金交易平臺',
		vetaFocusSubTitle: '資產增強，獲得額外的波動收益',
		vetaListTitle1: '結構性產品保證金系統',
		vetaListDesc1: 'UnionDefi是一個一體化的結構性產品保證金交易平臺，專業人士可以發佈結構性產品，用戶可以瀏覽和購買。',
		vetaListTitle2: '發佈產品',
		vetaListDesc2: '對於金融機構或專業量化團隊來說，他們可以發佈定製的結構化產品，既支持DeFi式（智能合約），也支持託管式（CEFFU）。',
		vetaListTitle3: '詢報價系統',
		vetaListDesc3: '對於高價值客戶，他們可以發佈報價請求，指定產品功能和等待專業團隊報價。',
		vetaTabsNav1: '本金保護型',
		vetaTabsNav2: '收益增強型',
		vetaTabsContentTitle1: '鯊魚鰭',
		vetaTabsContentSubTitle1: '保本且有保證的收益',
		vetaTabsContentText11: '在特定市場波動中獲得收益。',
		vetaTabsContentText12: '享受本金保護和收益的安全性。',
		vetaTabsContentTitle2: '保底雪球',
		vetaTabsContentSubTitle2: '高收益與可控風險的完美結合',
		vetaTabsContentText21: '下行風險有支撐。',
		vetaTabsContentText22: '充分利用高保護性收益的優勢。',

		vetaTabsContentTitle3: '雪球',
		vetaTabsContentSubTitle3: '下跌自帶緩衝，上漲不失良機',
		vetaTabsContentText31: '具有高概率的下行保護和高收益。',
		vetaTabsContentText32: '最大損失限制爲按初始價格買入基礎資產。',

		vetaTabsContentTitle4: 'FCN',
		vetaTabsContentSubTitle4: '保證收益或折扣價格接貨',
		vetaTabsContentText41: '具有下行保護和保證收益。',
		vetaTabsContentText42: '適用於以折扣價格建立倉位。',
		vetaEnterBtn: `進入 UnionDefi`,
		vetaBtn:'進入 UnionDefi'

	},
	en: {
		vetaFocusTitle: 'Structured Products Margin Trading Platform',
		vetaFocusSubTitle: 'Coins Enhanced with Extra Returns',
		vetaListTitle1: 'Structured Products Matching System',
		vetaListDesc1: 'UnionDefi is an all-in-one structured products margin trading platform, professionals can publish structured products and users can browse and buy them. ',
		vetaListTitle2: 'Publish Products',
		vetaListDesc2: 'For financial institutions or professional quant teams, they can publish customized  structured products, supporting both DeFi-style (smart contract) or custodial style (CEFFU) . ',
		vetaListTitle3: 'Request For Quotation',
		vetaListDesc3: 'For high net worth customers they can publish a request for quotation, specifying product  features and waiting for quotations from professional teams.',

		vetaTabsNav1: 'Capital Protection',
		vetaTabsNav2: 'Yield Enhancement',
		vetaTabsContentTitle1: 'Shark-Fin',
		vetaTabsContentSubTitle1: 'Principal preservation and guaranteed returns',
		vetaTabsContentText11: 'Optimize your gains within specific market fluctuations.',
		vetaTabsContentText12: 'Enjoy the security of guaranteed principal preservation and returns.',
		vetaTabsContentTitle2: 'Snowball with Floor',
		vetaTabsContentSubTitle2: 'A winning combination of high returns and controlled risk',
		vetaTabsContentText21: 'Downside risk with floor.',
		vetaTabsContentText22: 'Capitalize on high protective earn.',

		vetaTabsContentTitle3: 'Snowball',
		vetaTabsContentSubTitle3: 'High yield with price protection',
		vetaTabsContentText31: 'Downside protection and high returns with high probability.',
		vetaTabsContentText32: 'The maximum losses capped at converting into underlying asset with initial price.',

		vetaTabsContentTitle4: 'FCN',
		vetaTabsContentSubTitle4: 'Guaranteed return or discounted price',
		vetaTabsContentText41: 'Downside protection and guaranteed return.',
		vetaTabsContentText42: 'An excellent tool for establishing positions at discounted price.',
		vetaEnterBtn: `Enter UnionDefi`,
		vetaBtn:'Enter UnionDefi'

	},
}
export default VetaI18n