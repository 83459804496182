import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import walletEn1 from '../../images/wallet-en-1.png'
import walletEn2 from '../../images/wallet-en-2.png'
import walletEn3 from '../../images/wallet-en-3.png'
import walletEn4 from '../../images/wallet-en-4.png'
import walletEn5 from '../../images/wallet-en-5.png'
import walletZh1 from '../../images/wallet-zh-1.png'
import walletZh2 from '../../images/wallet-zh-2.png'
import walletZh3 from '../../images/wallet-zh-3.png'
import walletZh4 from '../../images/wallet-zh-4.png'
import walletZh5 from '../../images/wallet-zh-5.png'
import walletTr1 from '../../images/wallet-tr-1.png'
import walletTr2 from '../../images/wallet-tr-2.png'
import walletTr3 from '../../images/wallet-tr-3.png'
import walletTr4 from '../../images/wallet-tr-4.png'
import walletTr5 from '../../images/wallet-tr-5.png'

import '../../i18n'
import './index.css'

function Wallet({currentLanguage}) {
	const { t,  } = useTranslation()
	const list = useMemo(() => {
		return [
			{
				img: currentLanguage === 'en' ? walletEn1 : currentLanguage === 'zh' ? walletZh1 : walletTr1,
				title: t('walletListTitle1'),
				desc: t('walletListDesc1'),
			},
			{
				img: currentLanguage === 'en' ? walletEn2 : currentLanguage === 'zh' ? walletZh2 : walletTr2,
				title: t('walletListTitle2'),
				desc: t('walletListDesc2'),
			},
			{
				img: currentLanguage === 'en' ? walletEn3 : currentLanguage === 'zh' ? walletZh3 : walletTr3,
				title: t('walletListTitle3'),
				desc: t('walletListDesc3'),
			},
			{
				img: currentLanguage === 'en' ? walletEn4 : currentLanguage === 'zh' ? walletZh4 : walletTr4,
				title: t('walletListTitle4'),
				desc: t('walletListDesc4'),
			},
			{
				img: currentLanguage === 'en' ? walletEn5 : currentLanguage === 'zh' ? walletZh5 : walletTr5,
				title: t('walletListTitle5'),
				desc: t('walletListDesc5'),
			},
		]
	}, [t, currentLanguage])
	return (
		<div className="wallet-container">
			<section className="wallet-focus">
				<p className="title">{t('walletFocusTitle')}</p>
				<p className={`sub-title ${(currentLanguage === 'zh' || currentLanguage === 'tr') ? 'sub-titleEn' : ''}`}>{t('walletFocusSubTitle')}</p>
			</section>
			<section className="wallet-list">
				{list.map((item, index) => {
					return (
						<figure key={index} className={`wallet-item ${index % 2 ? 'reverse' : ''}`}>
							<img src={item.img} alt={item.title} />
							<figcaption>
								<h5 className="title">{item.title}</h5>
								<p className={`desc ${(currentLanguage === 'zh' || currentLanguage === 'tr') ? 'descEn' : ''}`}> {item.desc}</p>
							</figcaption>
						</figure>
					)
				})}
			</section>
		</div>
	)
}

export default Wallet
