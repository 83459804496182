import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import Veta from '../components/veta'
import Wallet from '../components/wallet'
// import Subscribe from '../components/subscribe'
import Download from '../components/download'
import Footer from '../components/footer'


import '../i18n'
import './index.css'
import {useCallback, useEffect, useMemo, useState} from "react";
import {getQueryValue, isApp} from "../untils";
import Privacy from "../components/privacy";


function App() {
  const { t,i18n } = useTranslation()
  const [currentLanguage , setLanguage] = useState('en')
  const [showLanguage,setShowLanguage] = useState(false)
  const [langLight , setLangLight] = useState(0)
  const getLanguage = (type) =>{
    if(type === 'en'){
      i18n.changeLanguage('en')
      setLanguage('en')
      setLangLight(0)
      window.history.replaceState(null,null,`?lang=en`)
    }else if(type === 'tr'){
      i18n.changeLanguage('tr')
      setLanguage('tr')
      setLangLight(1)
      window.history.replaceState(null,null,`?lang=tr`)
    }

    let metas = document.getElementsByTagName('meta')
    for(let i = 0;i<metas.length;i++){
      if(metas[i]['name'] === 'description'){
        metas[i]['content'] = t('metaDescription')
      }
    }
    document.title = t('metaTitle')
  }
  const changeLanguage = useCallback((index)=>{
    if(index === 0 ){
      i18n.changeLanguage('en')
      setLanguage('en')
      window.history.replaceState(null,null,`?lang=en`)
    }else if(index === 1){
      i18n.changeLanguage('tr')
      setLanguage('tr')
      window.history.replaceState(null,null,`?lang=tr`)
    }
    // else if(index === 2){
    //   i18n.changeLanguage('tr')
    //   setLanguage('tr')
    // }
    let metas = document.getElementsByTagName('meta')
    for(let i = 0;i<metas.length;i++){
      if(metas[i]['name'] === 'description'){
        metas[i]['content'] = t('metaDescription')
      }
    }
    document.title = t('metaTitle')
    setLangLight(index)
    setShowLanguage(false)
  },[i18n])
  const changeLang = () => {
    setShowLanguage(!showLanguage)
  }
  const handleMouseLeave = () => {
    setTimeout(()=>{
      setShowLanguage(false);
    },500)
  };
  const setDescOrShareStr = useMemo(() => {
    return () => {
      let shareStr = {
        title: t('metaTitle'),
        desc: t('metaDescription'),
        icon: 'https://chainpal.tongfudun.com/favicon.ico',
      }
      // console.log(shareStr)
      return window.btoa(
          unescape(window.encodeURIComponent(JSON.stringify(shareStr)))
      )
    }
  }, [t])
  // 获取信息

  useEffect(() => {
    if (isApp()) {
      let shareStr = setDescOrShareStr()
      let reg = new RegExp('chainmeetShare=[^&]*', 'gi')
      let url =
          window.location.search.indexOf('chainmeetShare') > -1
              ? window.location.search.replace(reg, `chainmeetShare=${shareStr}`)
              : `${window.location.search}${!window.location.search ? '?' : '&'
              }&chainmeetShare=${shareStr}`

      window.history.replaceState(null, null, url)
    }
  }, [setDescOrShareStr]);
  useEffect(()=>{
    if(getQueryValue('lang') === 'en'){
      getLanguage('en')
    }else if(getQueryValue('lang') === 'tr'){
      getLanguage('tr')
    }
    if(localStorage.getItem('language') === 'en'){
      setLanguage('en')
    }else if(localStorage.getItem('language') === 'zh'){
      setLanguage('zh')
    }else if(localStorage.getItem('language') === 'tr'){
      setLanguage('tr')
    }else {
      setLanguage('en')
    }
  },[])
  return (
    <div className={`home-container ${currentLanguage === 'tr' ? "home-container1" : ""}`}>
      <div className='main'>
        <Header props={{handleMouseLeave ,currentLanguage , showLanguage , changeLanguage  , langLight ,changeLang}}></Header>
        {/* 焦点文案 */}
        <section className='focus'>
          <h2 className='title'>{t('focusTitle')}</h2>
          <h3 className={`sub-title ${(currentLanguage === 'zh' || currentLanguage === 'tr') ? 'sub-titleEn' : ''}`}>  {t('focusSubTitle')}</h3>
          <div className={'docsTitle'} onClick={()=>{
            window.open('https://chainpal-aa-wallet.gitbook.io/chainpal-aa-wallet')
          }}>{t('docsTitle')}</div>
        </section>
        {/* privacy */}
        <Privacy currentLanguage = {currentLanguage}></Privacy>
        {/* wallet */}
        <Wallet currentLanguage = {currentLanguage}></Wallet>
        {/* veta */}
        <Veta currentLanguage = {currentLanguage}></Veta>
        {/* subscribe */}
        {/* <Subscribe></Subscribe> */}
        <Download currentLanguage = {currentLanguage}></Download>
        <Footer></Footer>

      </div>
    </div>
  );
}

export default App;
