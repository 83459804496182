import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import '../../i18n'
import './index.css'

import vetaLogo from '../../images/veta-logo.png'

function Privacy({ currentLanguage }) {
    const { t } = useTranslation()





    return (
        <div className="privacy-container">
            <div className="content">
                    <div className="title">
                        {t('PrivacyTitle')}
                    </div>
                    <div className="desc">
                        {t('PrivacyContent')}
                    </div>
            </div>
        </div>
    )
}

export default Privacy
