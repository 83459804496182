import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../i18n'
import './index.css'

import logoEn from '../../images/logo-en.png'

function Header({ props }) {
	const { t } = useTranslation()
	const [lang, setLang] = useState(0)
	const btnList = useMemo(() => {
		return [t('langEn'),  t('langTr')]
	}, [t])
	let { langLight, showLanguage, changeLanguage, changeLang, currentLanguage, handleMouseLeave } =
		props
	useEffect(() => {
		setLang(lang)
	}, [lang])
	const goDownload = useCallback(() => {
		const timer = setTimeout(() => {
			let mainDom = document.querySelector('.home-container')
			let downloadDom = document.querySelector('.download-container')
			if (mainDom) {
				mainDom.scroll({
					top: mainDom.scrollHeight - downloadDom.scrollHeight - 130,
					left: 0,
					behavior: 'smooth',
				})
			}
			timer && clearTimeout(timer)
		}, 200)
	}, [])

	return (
		<header>
			<h1 className="logo">
				<img alt="logo" src={logoEn} />
			</h1>
			<div className="btn">
				<span className="language" onClick={changeLang}></span>
				<div
					className={`download ${currentLanguage === 'tr' ? 'downloadTr' : ''}`}
					onClick={goDownload}
				>
					<span> {t('download')}</span>
				</div>
				{showLanguage ? (
					<div className="change-language" onMouseLeave={handleMouseLeave}>
						{btnList.map((item, index) => {
							return (
								<span
									className={langLight === index ? 'active' : ''}
									key={index}
									onClick={() => {
										changeLanguage(index)
									}}
								>
									{item}
								</span>
							)
						})}
					</div>
				) : (
					''
				)}
			</div>
		</header>
	)
}

export default Header
