import { useTranslation } from 'react-i18next'
import '../../i18n'
import './index.css'
import twitter from '../../images/twitter.png'
import medium from '../../images/medium.png'
import logoEn from '../../images/logo-en.png'

function Header() {
	const { t } = useTranslation()
	const goTwitter = () => {
		window.open('https://twitter.com/ChainPal_Web3')
	}
	const goMedium = () => {
		window.open('https://medium.com/@ChainPal')
	}
	return (
		<footer>
				<div className={'link'}>
					<img src={twitter} onClick={goTwitter} alt="twitter" />
					<img src={medium} onClick={goMedium} alt="medium" />
				</div>
			<p>{t('footerLeft')}{new Date().getFullYear()}{t('footerRight')}</p>
			<figure className="logo">
				<img src={logoEn} alt="logo" />
			</figure>
		</footer>
	)
}

export default Header
