import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import '../../i18n'
import './index.css'

import vetaLogo from '../../images/veta-logo.png'

function Veta({ currentLanguage }) {
	const { t } = useTranslation()
	const [currentNav, setCurrentNav] = useState(0)
	const list = useMemo(() => {
		return [
			{ title: t('vetaListTitle1'), desc: t('vetaListDesc1') },
			{ title: t('vetaListTitle2'), desc: t('vetaListDesc2') },
			{ title: t('vetaListTitle3'), desc: t('vetaListDesc3') },
		]
	}, [t])

	const tabNav = useMemo(() => {
		return [t('vetaTabsNav1'), t('vetaTabsNav2')]
	}, [t])

	const tabList = useMemo(() => {
		return [
			{
				title: t('vetaTabsContentTitle1'),
				subTitle: t('vetaTabsContentSubTitle1'),
				list: [t('vetaTabsContentText11'), t('vetaTabsContentText12')],
			},
			{
				title: t('vetaTabsContentTitle2'),
				subTitle: t('vetaTabsContentSubTitle2'),
				list: [t('vetaTabsContentText21'), t('vetaTabsContentText22')],
			},
			{
				title: t('vetaTabsContentTitle3'),
				subTitle: t('vetaTabsContentSubTitle3'),
				list: [t('vetaTabsContentText31'), t('vetaTabsContentText32')],
			},
			{
				title: t('vetaTabsContentTitle4'),
				subTitle: t('vetaTabsContentSubTitle4'),
				list: [t('vetaTabsContentText41'), t('vetaTabsContentText42')],
			},
		]
	}, [t])

	const tabListM = useMemo(() => {
		return type => {
			return tabList.filter((item, index) => (type ? index > 1 : index < 2))
		}
	}, [tabList])

	const handlerClickNav = useCallback(index => {
		setCurrentNav(index)
	}, [])

	// 点击 enter 按钮
	const handlerEnter = useCallback(() => {
		window.open('https://app.uniondefi.net?lng=en')
	}, [])

	return (
		<div className="veta-container">
			<section
				className={`${
					currentLanguage === 'en'
						? 'veta-focus'
						: currentLanguage === 'zh'
						? 'veta-focus-1 '
						: 'veta-focus-2'
				}`}
			>
				<figcaption>
					<figure className="logo">
						<img src={vetaLogo} alt="logo" />
					</figure>
					<p className="title">{t('vetaFocusTitle')}</p>
					<p
						className={`sub-title ${
							currentLanguage === 'zh' || currentLanguage === 'tr' ? 'sub-titleEn' : ''
						}`}
					>
						{t('vetaFocusSubTitle')}
					</p>
				</figcaption>
			</section>
			<section className="veta-list">
				{list.map((item, index) => {
					return (
						<div key={index} className="list-item">
							<p className="title">{t(item.title)}</p>
							<p className="desc">{t(item.desc)}</p>
						</div>
					)
				})}
			</section>
			{/* 大屏展示 */}
			<section className="veta-tabs">
				<nav className="tab">
					{tabNav.map((item, index) => {
						return (
							<span
								key={index}
								onClick={() => {
									handlerClickNav(index)
								}}
								className={currentNav === index ? 'active' : ''}
							>
								{item}
							</span>
						)
					})}
				</nav>
				<ul className="tab-content">
					{tabList.map((item, index) => {
						return (
							<li
								key={index}
								className={`list-item ${
									(currentNav && index > 1) || (!currentNav && index < 2) ? 'active' : ''
								}`}
							>
								<p className="title">{t(item.title)}</p>
								<p className="sub-title">{t(item.subTitle)}</p>
								{item?.list.map((e, i) => {
									return (
										<p key={i} className="text">
											{e}
										</p>
									)
								})}
							</li>
						)
					})}
				</ul>
			</section>
			{/* 小屏展示 */}
			<section className="veta-tabs-m">
				<div className="tab">{t('vetaTabsNav1')}</div>
				<ul className="tab-content tab-content-0">
					{tabListM(0).map((item, index) => {
						return (
							<li key={index} className="list-item">
								<h4 className="title">{t(item.title)}</h4>
								<h5 className="sub-title">{t(item.subTitle)}</h5>
								{item?.list.map((e, i) => {
									return (
										<p key={i} className="text">
											{e}
										</p>
									)
								})}
							</li>
						)
					})}
				</ul>
				<div className="tab">{t('vetaTabsNav2')}</div>
				<ul className="tab-content tab-content-1">
					{tabListM(1).map((item, index) => {
						return (
							<li key={index} className="list-item">
								<h4 className="title">{t(item.title)}</h4>
								<h5 className="sub-title">{t(item.subTitle)}</h5>
								{item?.list.map((e, i) => {
									return (
										<p key={i} className="text">
											{e}
										</p>
									)
								})}
							</li>
						)
					})}
				</ul>
			</section>
			<div className="enter-btn-wrap">
				<div className="enter-btn">
					<span className="btn" onClick={handlerEnter}>
						{t('vetaEnterBtn')}
					</span>
				</div>
			</div>
		</div>
	)
}

export default Veta
